<template>
<CHeader with-subheader>
    <!-- <CToggler white in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
    <CToggler in-header class="ml-3 d-md-down-none" @click="$store.commit('toggleSidebarDesktop')" /> -->
    <!-- Add Hamburger Wite Image - Need to check with function -->

    <CHeaderBrand class="px-3" to="/">
        <CImg name="logo" height="20" alt="Logo" src="img/logo/logo.png"></CImg>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
        <!-- <CHeaderNavItem class="px-3">
            <CHeaderNavLink to="/dashboard">
                Home
            </CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem class="px-3">
            <CHeaderNavLink to="" exact>
                Profile
            </CHeaderNavLink>
        </CHeaderNavItem>
         <CHeaderNavItem class="px-3">
            <CHeaderNavLink>
                About Us
            </CHeaderNavLink>
        </CHeaderNavItem> -->
        <CHeaderNavItem class="px-1" v-if="!customer_access_token">
            <CHeaderNavLink to="/restaurant-signup">
                Partner with Us
            </CHeaderNavLink>
        </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mobile-view">

        <CHeaderNavItem class="" v-if="customer_access_token">
            <CHeaderNavLink>
                <div class="c-avatar d-lg-none">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img" class="c-icon c-icon-custom-size" height="25">
                        <polygon fill="var(--ci-primary-color, currentColor)" points="48 48 176 48 176 16 16 16 16 176 48 176 48 48" class="ci-primary"></polygon>
                        <path fill="var(--ci-primary-color, currentColor)" d="M176,176V80H80v96h96Zm-64-64h32v32H112Z" class="ci-primary"></path>
                        <polygon fill="var(--ci-primary-color, currentColor)" points="328 48 464 48 464 176 496 176 496 16 328 16 328 48" class="ci-primary"></polygon>
                        <path fill="var(--ci-primary-color, currentColor)" d="M432,176V80H336v96h96Zm-64-64h32v32H368Z" class="ci-primary"></path>
                        <polygon fill="var(--ci-primary-color, currentColor)" points="176 464 48 464 48 336 16 336 16 496 176 496 176 464" class="ci-primary"></polygon>
                        <path fill="var(--ci-primary-color, currentColor)" d="M176,336H80v96h96V336Zm-32,64H112V368h32Z" class="ci-primary"></path>
                        <polygon fill="var(--ci-primary-color, currentColor)" points="464 464 328 464 328 496 496 496 496 336 464 336 464 464" class="ci-primary"></polygon>
                        <polygon fill="var(--ci-primary-color, currentColor)" points="272 304 400 304 400 368 432 368 432 272 272 272 272 304" class="ci-primary"></polygon>
                        <polygon fill="var(--ci-primary-color, currentColor)" points="432 432 432 400 240 400 240 272 80 272 80 304 208 304 208 432 432 432" class="ci-primary"></polygon>
                        <rect width="32" height="96" x="208" y="80" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect>
                        <polygon fill="var(--ci-primary-color, currentColor)" points="80 240 304 240 304 80 272 80 272 208 80 208 80 240" class="ci-primary"></polygon>
                        <rect width="96" height="32" x="336" y="208" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect>
                        <rect width="32" height="32" x="336" y="336" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect>
                        <rect width="32" height="32" x="272" y="336" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect>
                    </svg>
                </div>
            </CHeaderNavLink>
        </CHeaderNavItem>

        <CHeaderNavItem class="px-2 " v-if="!customer_access_token">
            <CHeaderNavLink to="/login"> Sign In </CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem class="px-1 d-md-down-none" v-if="!customer_access_token">
            <CHeaderNavLink to="/register"> Sign Up </CHeaderNavLink>
        </CHeaderNavItem>

            <CHeaderNavItem class="mobile-view"  v-if="!(customer_access_token && active_reservation_id &&
          hasPermission('CUSTOMER_ORDER') &&
          hasPermission(tenant_id))
          
        " >
            <CHeaderNavLink >  </CHeaderNavLink>
        </CHeaderNavItem>

<!--        <CHeaderNavItem  v-if="-->
<!--          customer_access_token && active_reservation_id &&-->
<!--          hasPermission('CUSTOMER_ORDER') &&-->
<!--          hasPermission(tenant_id)-->
<!--        " >-->
<!--            <CHeaderNavLink>-->
<!--                <TheHeaderDropdownCart />-->
<!--            </CHeaderNavLink>-->
<!--        </CHeaderNavItem>-->



        <CHeaderNavItem class="px-3 d-md-down-none" v-if="customer_access_token">
            <CHeaderNavLink> Hi {{ user_name }} </CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem>
            <CHeaderNavLink class="">
                <CIcon in-header class="ml-1 pt-2 d-lg-none hamburger" @click.native="$store.commit('toggleSidebarMobile')" height="30" src="img/menu.png" />
                <CIcon in-header class="mr-3 ml-2 pt-2 d-md-down-none hamburger" @click.native="$store.commit('toggleSidebarDesktop')" height="30" src="img/menu.png" />
            </CHeaderNavLink>
        </CHeaderNavItem>
    </CHeaderNav>

</CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import TheHeaderDropdownCart from "./TheHeaderDropdownCart";
import {
    mapState,
    mapActions,
    mapGetters
} from "vuex";
import commonMixin from "@/mixins/common";
export default {
    name: "TheHeader",
    components: {
        TheHeaderDropdownAccnt,
        TheHeaderDropdownCart,
    },
    mixins: [commonMixin],
    computed: {
        ...mapState("user", ["user_name", "customer_access_token","active_reservation_id"]),
        ...mapGetters("user", ["loggedIn"]),
    },
    mounted() {
        let tenant_id = this.$route.params.tenant_id;
        this.tenant_id = tenant_id;
    },
    data() {
        return {
            tenant_id: "",
        };
    },
};
</script>

<style scoped>
.c-header {
    background: linear-gradient(to right, #09092f, #3f2252);
    border-bottom: 1px solid #d8dbe000;
  position: relative !important;
}

.c-header .c-header-nav .c-header-nav-link,
.c-header .c-header-nav .c-header-nav-btn {
    color: rgb(255 255 255) !important;
}

.txt-color {
    color: #ffffff !important;
}

.hamburger {
    cursor: pointer;
}

.logo-size {
    width: 80px;
    height: auto;
}

@media (max-width: 768px) {
    .mobile-view {
        margin-left: auto !important;
    }
}

@media (max-width: 575.98px) {
    .mobile-view {
        margin-left:auto !important;
    }
}
</style>
