<template>
<CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0" v-if="customer_access_token">
    <template #toggler>
        <CHeaderNavLink>
            <div class="c-avatar">
                <img src="img/avatars/6.jpg" class="c-avatar-img" />
            </div>
        </CHeaderNavLink>
    </template>
    <!-- <CDropdownHeader tag="div" class="text-center dropdown-design" color="dark">
        <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem class="text-white" to="/profile">
        <CIcon  name="cil-user" /> Profile
    </CDropdownItem>

    <CDropdownItem class="text-white" to="/restaurant-signup">
        <CIcon  name="cil-user" /> Partner With Us
    </CDropdownItem>

    <CDropdownItem class="text-white" to="/login">
        <CIcon name="cil-user" /> Sign In
    </CDropdownItem>

    <CDropdownItem class="text-white" to="/register">
        <CIcon name="cil-user" /> Sign Up
    </CDropdownItem>
    <CDropdownItem class="text-white" @click="logout()">
        <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem> -->
</CDropdown>
</template>

<script>
import {
    mapState,
    mapActions,
    mapGetters
} from "vuex";
export default {
    name: "TheHeaderDropdownAccnt",
    data() {
        return {
            itemsCount: 42,
        };
    },
    methods: {
        ...mapActions("user", ["destroyToken"]),
        async logout() {
            await this.destroyToken();
        },
    },
    computed: {
        ...mapState("user", ["user_name", "customer_access_token"]),
        ...mapGetters("user", ["loggedIn"]),
    },
};
</script>

<style>

</style>

<style scoped>
.c-icon {
    margin-right: 0.3rem;
}
.dropdown-design{
   background-color: #000000 !important;
    color: #ffffff;
     border: 1px solid #171718 !important;
}
</style>
